<template>
<div>
  <v-container>
    <v-row>
      <v-col cols='12'>
        <v-card-title >
          2. Запрещенные действия при тушении пожара
        </v-card-title>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2" class="d-flex flex-column align-center justify-center">
        <v-hover
        v-slot="{ hover }"
        open-delay="200"
        >
          <v-card
            flat
            class="d-flex align-center justify-center pa-5 white mb-10"
            width="250"
            :elevation="hover ? 4 : 2"
            style="cursor:pointer;"
            v-model="tabs"
            @click="viewTab"

          >
            <v-img
              absolute
              lazy-src="img/material/alarm.svg"
              max-height="100%"
              max-width="50%"
              contain
              src="img/material/alarm.svg"
            >
            </v-img>
          </v-card>
        </v-hover>
        <v-hover
        v-slot="{ hover }"
        open-delay="200"
        >
          <v-card
            flat
            class="d-flex align-center justify-center pa-5 white mb-10"
            width="250"
            :elevation="hover ? 4 : 2"
            style="cursor:pointer;"
            ref="flash"
            @click="viewTab2()"

          >
            <v-img
              lazy-src="img/material/doorway.svg"
              max-height="100%"
              max-width="50%"
              contain
              src="img/material/doorway.svg"
            >
            </v-img>
          </v-card>
        </v-hover>
        <v-hover
        v-slot="{ hover }"
        open-delay="200"
        >
          <v-card
            flat
            class="d-flex align-center justify-center pa-5 white mb-10"
            cursor:pointer
            width="250"
            :elevation="hover ? 4 : 2"
            style="cursor:pointer;"
            v-model="tabs"
            @click="viewTab3"
          >
            <v-img
              lazy-src="img/material/elevator-i.svg"
              max-height="100%"
              max-width="50%"
              contain
              src="img/material/elevator-i.svg"
            >
            </v-img>
          </v-card>
        </v-hover>
        <v-hover
        v-slot="{ hover }"
        open-delay="200"
        >
          <v-card
            flat
            class="d-flex align-center justify-center pa-5 white mb-10"
            cursor:pointer
            width="250"
            :elevation="hover ? 4 : 2"
            style="cursor:pointer;"
            v-model="tabs"
            @click="viewTab4"
          >
            <v-img
              lazy-src="img/material/running.svg"
              max-height="100%"
              max-width="50%"
              contain
              src="img/material/running.svg"
            >
            </v-img>
          </v-card>
        </v-hover>

      </v-col>
      <v-col cols="8">
          <v-card
            flat
            class="d-flex align-center justify-center pa-2 white mb-10"
            height="200px"
          >
            <v-card-text v-model="message" class="title d-flex text-center justify-center font-weight-bold">
              {{ message }}
            </v-card-text>
          </v-card>
          <v-card
            flat
            class="d-flex align-center justify-center pa-5 white"
            cursor:pointer
            width="100%"
            style="cursor:pointer;"
            relative
          >
            <v-img
              lazy-src="img/material/fire-c.gif"
              max-height="100%"
              max-width="70%"
              contain
              absolute
              left
              src="img/material/stop.svg"
            >
            </v-img>
          </v-card>
      </v-col>
      <v-col cols="2">
        <v-hover
        v-slot="{ hover }"
        open-delay="200"
        >
          <v-card
            flat
            class="d-flex align-center justify-center pa-5 white mb-10"
            cursor:pointer
            width="250"
            :elevation="hover ? 4 : 2"
            style="cursor:pointer;"
            v-model="tabs"
            @click="viewTab5"
          >
            <v-img
              lazy-src="img/material/cabinet.svg"
              max-height="100%"
              max-width="50%"
              contain
              src="img/material/cabinet.svg"
            >
            </v-img>
          </v-card>
        </v-hover>
        <v-hover
        v-slot="{ hover }"
        open-delay="200"
        >
          <v-card
            flat
            class="d-flex align-center justify-center pa-5 white mb-10"
            cursor:pointer
            width="250"
            :elevation="hover ? 4 : 2"
            style="cursor:pointer;"
            v-model="tabs"
            @click="viewTab6"
          >
            <v-img
              lazy-src="img/material/stopwatch.svg"
              max-height="100%"
              max-width="50%"
              contain
              src="img/material/stopwatch.svg"
            >
            </v-img>
          </v-card>
        </v-hover>
        <v-hover
        v-slot="{ hover }"
        open-delay="200"
        >
          <v-card
            flat
            class="d-flex align-center justify-center pa-5 white mb-10"
            cursor:pointer
            width="250"
            :elevation="hover ? 4 : 2"
            style="cursor:pointer;"
            v-model="tabs"
            @click="viewTab7"
          >
            <v-img
              lazy-src="img/material/panic.svg"
              max-height="100%"
              max-width="50%"
              contain
              src="img/material/panic.svg"
            >
            </v-img>
          </v-card>
        </v-hover>
        <v-hover
        v-slot="{ hover }"
        open-delay="200"
        >
          <v-card
            flat
            class="d-flex align-center justify-center pa-5 white mb-10"
            cursor:pointer
            width="250"
            :elevation="hover ? 4 : 2"
            style="cursor:pointer;"
            v-model="tabs"
            @click="viewTab8"
          >
            <v-img
              lazy-src="img/material/socket.svg"
              max-height="100%"
              max-width="50%"
              contain
              src="img/material/socket.svg"
            >
            </v-img>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </v-container>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="d-flex flex-column justify-center">
        <Hint></Hint>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>
<script>
import Hint from '@/components/Hint.vue'
export default {
  components: {
    Hint
  },
  data () {
    return {
      tabs: [
        { id: '1', text: 'Мешкать и игнорировать сообщение о пожаре, сигналы пожарной сигнализации' },
        { id: '2', text: 'Открывать окна и двери, разбивать стекла, так как приток свежего воздуха поддерживает горение' },
        { id: '3', text: 'Пользоваться лифтом' },
        { id: '4', text: 'Передвигаться во весь рост по задымленным помещениям' },
        { id: '5', text: 'Пытаться спрятаться от огня в дальних помещениях, шкафах' },
        { id: '6', text: 'Переоценивать свои силы в тушении и медлить с вызовом пожарных' },
        { id: '7', text: 'Поддаваться панике' },
        { id: '8', text: 'Тушить водой электроприборы, включенные в сеть. Загоревшийся электроприбор необходимо отключить от сети, а затем залить водой или накрыть плотной тканью' }
      ],
      message: '',
      text: 'Lorem '
    }
  },
  methods: {
    viewTab: function () {
      this.message = this.tabs[0].text
    },
    viewTab2: function () {
      this.message = this.tabs[1].text
    },
    viewTab3: function () {
      this.message = this.tabs[2].text
    },
    viewTab4: function () {
      this.message = this.tabs[3].text
    },
    viewTab5: function () {
      this.message = this.tabs[4].text
    },
    viewTab6: function () {
      this.message = this.tabs[5].text
    },
    viewTab7: function () {
      this.message = this.tabs[6].text
    },
    viewTab8: function () {
      this.message = this.tabs[7].text
    }
  }
}
</script>
