var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card-title',[_vm._v(" 2. Запрещенные действия при тушении пожара ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column align-center justify-center",attrs:{"cols":"2"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center justify-center pa-5 white mb-10",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","width":"250","elevation":hover ? 4 : 2},on:{"click":_vm.viewTab},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-img',{attrs:{"absolute":"","lazy-src":"img/material/alarm.svg","max-height":"100%","max-width":"50%","contain":"","src":"img/material/alarm.svg"}})],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{ref:"flash",staticClass:"d-flex align-center justify-center pa-5 white mb-10",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","width":"250","elevation":hover ? 4 : 2},on:{"click":function($event){return _vm.viewTab2()}}},[_c('v-img',{attrs:{"lazy-src":"img/material/doorway.svg","max-height":"100%","max-width":"50%","contain":"","src":"img/material/doorway.svg"}})],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center justify-center pa-5 white mb-10",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","cursor:pointer":"","width":"250","elevation":hover ? 4 : 2},on:{"click":_vm.viewTab3},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-img',{attrs:{"lazy-src":"img/material/elevator-i.svg","max-height":"100%","max-width":"50%","contain":"","src":"img/material/elevator-i.svg"}})],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center justify-center pa-5 white mb-10",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","cursor:pointer":"","width":"250","elevation":hover ? 4 : 2},on:{"click":_vm.viewTab4},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-img',{attrs:{"lazy-src":"img/material/running.svg","max-height":"100%","max-width":"50%","contain":"","src":"img/material/running.svg"}})],1)]}}])})],1),_c('v-col',{attrs:{"cols":"8"}},[_c('v-card',{staticClass:"d-flex align-center justify-center pa-2 white mb-10",attrs:{"flat":"","height":"200px"}},[_c('v-card-text',{staticClass:"title d-flex text-center justify-center font-weight-bold",model:{value:(_vm.message),callback:function ($$v) {_vm.message=$$v},expression:"message"}},[_vm._v(" "+_vm._s(_vm.message)+" ")])],1),_c('v-card',{staticClass:"d-flex align-center justify-center pa-5 white",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","cursor:pointer":"","width":"100%","relative":""}},[_c('v-img',{attrs:{"lazy-src":"img/material/fire-c.gif","max-height":"100%","max-width":"70%","contain":"","absolute":"","left":"","src":"img/material/stop.svg"}})],1)],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center justify-center pa-5 white mb-10",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","cursor:pointer":"","width":"250","elevation":hover ? 4 : 2},on:{"click":_vm.viewTab5},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-img',{attrs:{"lazy-src":"img/material/cabinet.svg","max-height":"100%","max-width":"50%","contain":"","src":"img/material/cabinet.svg"}})],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center justify-center pa-5 white mb-10",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","cursor:pointer":"","width":"250","elevation":hover ? 4 : 2},on:{"click":_vm.viewTab6},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-img',{attrs:{"lazy-src":"img/material/stopwatch.svg","max-height":"100%","max-width":"50%","contain":"","src":"img/material/stopwatch.svg"}})],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center justify-center pa-5 white mb-10",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","cursor:pointer":"","width":"250","elevation":hover ? 4 : 2},on:{"click":_vm.viewTab7},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-img',{attrs:{"lazy-src":"img/material/panic.svg","max-height":"100%","max-width":"50%","contain":"","src":"img/material/panic.svg"}})],1)]}}])}),_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"d-flex align-center justify-center pa-5 white mb-10",staticStyle:{"cursor":"pointer"},attrs:{"flat":"","cursor:pointer":"","width":"250","elevation":hover ? 4 : 2},on:{"click":_vm.viewTab8},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},[_c('v-img',{attrs:{"lazy-src":"img/material/socket.svg","max-height":"100%","max-width":"50%","contain":"","src":"img/material/socket.svg"}})],1)]}}])})],1)],1)],1),_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"d-flex flex-column justify-center",attrs:{"cols":"12"}},[_c('Hint')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }